<template>
    <div class="dashboard-page">
        <div class="container text-center pt-5 mt-5" v-if="step === 0">
            <div class="mt-5">&nbsp;</div>
            <p class="mt-5">
                <img src="../../assets/images/logo-full.white.png" alt="Remy Logo" style="height: 90px">
            </p>
            <h1 class="text-white mt-5 mb-5">TFWA CANNES 2022<br/>EVENT CHECK-IN</h1>
            <p class="mb-5">
                <button class="btn btn-danger btn-remy" @click.prevent="initScan">
                    CLICK TO START SCANNING
                </button>
            </p>
        </div>
        <template v-else-if="step === 1">
            <div class="container">
                <div class="row">
                    <div class="col px-5 py-3">
                        <div class="camera position-relative px-5">
                            <qrcode-stream :camera="camera" @decode="onDecode">
                                <div class="camera-switch">
                                    <button @click="switchCamera" class="btn">
                                        <img src="../../assets/images/switch.png" alt="switch camera">
                                    </button>
                                </div>
                                <div class="qr-header text-center">
                                    <button class="btn btn-dark my-2">SCAN TO CHECK-IN VISITOR</button>
                                </div>
                                <div class="qr-footer text-center">
                                    <button class="btn btn-dark my-2">SCAN TO CHECK-IN VISITOR</button>
                                    <p>
                                        <small>Can’t scan the code? Exit and re-open the link again.</small>
                                    </p>
                                </div>
                            </qrcode-stream>
                            <div class="qr-border">
                                <span class="holder"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="modal fade" ref="errorModal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <img src="../../assets/images/logo-full-2.png" alt="Logo" style="height: 50px">
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex; margin: 10px auto 30px;">
                            <span class="swal2-x-mark">
                                <span class="swal2-x-mark-line-left"></span>
                                <span class="swal2-x-mark-line-right"></span>
                            </span>
                        </div>
                        <h2 class="font-circe text-danger text-center fw-light mb-4">Oops error !</h2>
                        <p class="text-center"><b>Please check</b></p>
                        <ul style="width: 285px; text-align: left; margin: 0 auto" class="fw-light mb-3">
                            <li>Is the lighting too dim?</li>
                            <li>Is the scanning angle too far?</li>
                            <li>Is the QR code too small or too big?</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="errorModalCustomText">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <img src="../../assets/images/logo-full-2.png" alt="Logo" style="height: 50px">
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex; margin: 10px auto 30px;">
                            <span class="swal2-x-mark">
                                <span class="swal2-x-mark-line-left"></span>
                                <span class="swal2-x-mark-line-right"></span>
                            </span>
                        </div>
                        <h2 class="font-circe text-danger text-center fw-light mb-4">Oops error !</h2>
                        <p class="text-center">{{ error_text }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" ref="successModal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <img src="../../assets/images/logo-full-2.png" alt="Logo" style="height: 50px">
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex; margin: 10px auto 30px">
                            <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                            <span class="swal2-success-line-tip"></span>
                            <span class="swal2-success-line-long"></span>
                            <div class="swal2-success-ring"></div>
                            <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                            <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                        </div>
                        <h2 class="font-circe text-success text-center fw-light mb-4">Successful check in!</h2>
                        <template v-if="booking.is_host === 1">
                            <p class="text-center">
                                Hi:
                                <span class="text-danger">{{ booking.retailer_name }}</span>
                                . Welcome to our Remy Cointreau Booth.
                            </p>
                        </template>
                        <template v-else-if="booking.is_host === 0">
                            <p class="text-center">
                                Hi:
                                <span class="text-danger">{{ booking.participant_name }}</span>
                                . Welcome to our Remy Cointreau Booth.<br/> I will have
                                <span class="text-danger">{{ booking.user_name }}</span>
                                to assist you. Please wait.
                            </p>
                            <table class="table table-bordered" style="width: 300px; margin: 0 auto">
                                <tr v-for="slot in selection_slots" v-if="_.findIndex(slot.participants, {id: booking.participant_id}) >= 0">
                                    <td style="width: 100px" class="text-center">{{ moment(slot.time_from, 'HH:mm:ss').format('HHmm')}} - {{ moment(slot.time_to, 'HH:mm:ss').format('HHmm') }}</td>
                                    <td>
                                        {{ slot.zone_name}}
                                        <span v-if="slot.zone_id === 8"><br/>Location: {{ slot.description }}</span>
                                    </td>
                                </tr>
                            </table>
                            <br/>
                            <p class="text-center">
                                <small>
                                    Partner: {{ booking.retailer_name }}
                                    <span class="mx-2">|</span>
                                    Host by: {{ booking.user_name }}
                                    <span class="mx-2">|</span>
                                    Meeting Date: {{ moment(booking.date).format('D MMM') }}
                                </small>
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
main {
    min-height : 768px;
}
h1 {
    font-size : 60px;
}
.modal-header {
    border-bottom : 0;
}
.modal-footer {
    border-top : 0;
}
.qr-border {
    position : absolute;
    width    : 400px;
    height   : 400px;
    top      : calc(50% - 200px);
    left     : calc(50% - 200px);
}
.qr-border:before,
.qr-border:after,
.qr-border > :last-child:before,
.qr-border > :last-child:after {
    position     : absolute;
    width        : 60px;
    height       : 50px;
    border-color : white;
    border-style : solid;
    content      : ' ';
    z-index      : 999;
}

.qr-border:before {
    top                    : 10px;
    left                   : 10px;
    border-width           : 5px 0 0 5px;
    border-top-left-radius : 18px;
}

.qr-border:after {
    top                     : 10px;
    right                   : 10px;
    border-width            : 5px 5px 0 0;
    border-top-right-radius : 18px;
}

.qr-border > :last-child:before {
    bottom                     : 10px;
    right                      : 10px;
    border-width               : 0 5px 5px 0;
    border-bottom-right-radius : 18px;

}

.qr-border > :last-child:after {
    bottom                    : 10px;
    left                      : 10px;
    border-width              : 0 0 5px 5px;
    border-bottom-left-radius : 18px;
}
.camera-switch {
    position   : absolute;
    bottom     : 115px;
    width      : 100%;
    text-align : center;
    z-index    : 1000;
}
.camera-switch img {
    height : 60px;
}
.qr-header {
    width      : 100%;
    background : #F5F4F3;
    position   : absolute;
    top        : 0;
    left       : 0;
}
.qr-footer {
    width      : 100%;
    background : #F5F4F3;
    position   : absolute;
    bottom     : 0;
    left       : 0;
}
</style>
<script>
import { mapState } from "vuex";
import { QrcodeStream } from 'vue-qrcode-reader'
import Api from "@/services/Api";
import moment from 'moment-timezone';
import { Modal } from 'bootstrap';
import _ from 'lodash';

export default {
    name: 'HomePage',
    components: {
        QrcodeStream
    },
    data() {
        return {
            moment,
            step: 0,
            camera: 'auto',
            booking: {
                is_host: '',
                date: '',
                retailer_name: '',
                participant_name: '',
                participant_id: 0,
                user_name: '',
                booking_id: 0
            },
            booking_detail: {},
            zones: [],
            error_text: '',
            interval_scan: null,
            interval_timer: 0
        }
    },
    computed: {
        ...mapState(['version']),
        selection_slots() {
            let self = this;
            let arr = [];
            let selected_slots = _.orderBy(self.booking_detail.slots, ['time_from', 'asc']);

            _.each(selected_slots, (item) => {
                let existedIdx = _.findIndex(arr, { zone_id: item.zone_id, time_to: item.time_from });
                if (existedIdx < 0) {
                    let zone = _.find(self.zones, { id: item.zone_id });
                    let participants = []
                    let tmp = _.find(self.booking_detail.data_zones, { id: item.zone_id });
                    if (!_.isEmpty(tmp)) {
                        tmp = _.find(tmp.data_slots, { id: item.id });
                        if (!_.isEmpty(tmp)) {
                            participants = tmp.participants;
                        }
                    }

                    arr.push({
                        zone_id: item.zone_id,
                        zone_name: self.zoneName(zone, self.booking_detail.booking_date),
                        time_from: item.time_from,
                        time_to: item.time_to,
                        slot_ids: [item.id],
                        participants: participants,
                        description: item.description,
                    })
                }
                else{
                    arr[existedIdx].time_to = item.time_to;
                    arr[existedIdx].slot_ids.push(item.id);
                }
            });
            return _.orderBy(arr, ['zone_id', 'asc']);
        },
    },
    methods: {
        zoneName(zone, date) {
            if (zone.id === 4 && date === '2022-10-03') {
                return 'COCKTAIL HOUR with ERIC VALLAT, GROUP CEO';
            }
            else if (zone.id === 5 && date === '2022-10-04') {
                return 'THE BOTANIST MARTINI HOUR with ADAM HANNETT';
            }
            else if (zone.id === 5 && date === '2022-10-05') {
                return 'METAXA 12 STARS AMBER HOUR with ARIS CHATZIANTONIOU';
            }

            return zone.name;
        },
        initHome() {
            document.body.classList.remove('bg-dark');
            document.body.classList.add('bg-home1');
            clearInterval(this.interval_scan);
            this.step = 0;
        },
        initScan() {
            let self = this;

            document.body.classList.remove('bg-home1');
            document.body.classList.add('bg-gray');

            self.step = 1;
            self.interval_timer = 0;

            self.interval_scan = setInterval(function() {
                self.interval_timer++;

                if (self.interval_timer >= 1000 && self.step === 1) {
                    self.initHome();

                    let modal = new Modal(self.$refs.errorModal)
                    modal.show();

                    setTimeout(function() {
                        modal.hide();
                    }, 10000);
                }
            }, 1000);
        },
        switchCamera() {
            switch(this.camera){
                case 'front':
                    this.camera = 'rear'
                    break
                case 'rear':
                    this.camera = 'front'
                    break
                case 'auto':
                    this.camera = 'front'
                    break
            }
        },
        async onDecode(decodedString) {
            this.step = 2;

            try{
                let response = await Api.checkIn({
                    text: decodedString
                });

                this.booking = response.booking;
                this.booking_detail = await Api.getBookingDetail(this.booking.booking_id);

                let modal = new Modal(this.$refs.successModal)
                modal.show();

                this.initHome();
            }catch(err){
                this.error_text = err;

                let modal = new Modal(this.$refs.errorModalCustomText)
                modal.show();

                this.initHome();
            }
        }
    },
    async mounted() {
        document.body.classList.add('bg-fixed');
        this.initHome();
        this.zones = await Api.getBookingZones();
    },
    destroyed() {
        document.body.classList.remove('bg-fixed');
    }
}
</script>
