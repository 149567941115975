import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

import lodash from 'lodash';
import VueLodash from 'vue-lodash'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'fontawesome-4.7/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';

Vue.config.productionTip = false

Vue.use(VueSweetalert2);
Vue.use(VueLodash, { lodash: lodash })


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
