import axios from "axios";
import _ from 'lodash';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
    async checkIn(payload) {
        try{
            let response = await axios.post('/v1/booking/checkIn', payload);
            return response.data;
        }catch(err){
            throw (!_.isUndefined(err.response.data.message)) ? err.response.data.message : 'An error has occurred';
        }
    },
    async getBookingDetail(id) {
        try{
            let response = await axios.get('/v1/booking/detail?booking_id=' + encodeURIComponent(id));
            return response.data;
        }catch(err){
            throw (!_.isUndefined(err.response.data.message)) ? err.response.data.message : 'An error has occurred';
        }
    },
    async getBookingZones() {
        try{
            let response = await axios.get('/v1/booking/zones');
            return response.data;
        }catch(err){
            throw (!_.isUndefined(err.response.data.message)) ? err.response.data.message : 'An error has occurred';
        }
    },
}
