import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '../views/Pages/HomePage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    }
]

const router = new VueRouter({
    routes
})

export default router
